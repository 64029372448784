<template>
  <div class="d-flex flex-column align-center">
    {{ headline }}
    <div class=" mt-2">
      <v-btn @click="gen" :loading="loading">
        Vygenerovať
      </v-btn>
    </div>
    <div class="mb-5 mt-1">
      <v-btn-toggle
          v-model="selectedCount"
          mandatory
      >
        <div
            v-for="index in maxCount" :key="index">
          <v-btn
              :disabled="loading"
              small>
            {{ index }}
          </v-btn>
        </div>
      </v-btn-toggle>
    </div>
    <div v-if="pairs.length > 0">
      👇 Tieto kódy nadiktuj obsluhe 👇
    </div>
    <div v-for="(pair, i) in pairs" :key="i" class="d-flex align-center mt-2">
      <div>
        Code {{ i + 1 }}: <b>{{ pair.code }}</b>
      </div>
      <v-btn class="ml-5"
             icon
             elevation="2"
             small
             :href="pair.url" target="_blank">
        <v-icon>mdi-arrange-bring-forward</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: "McDonalds",
  data: () => ({
    maxCount: 5,
    selectedCount: 0,
    sessionId: null,
    loading: false,
    pairs: [],
    headline: "Klikni na tlačidlo nižšie pre generáciu 🍔",
  }),
  mounted() {
    this.sessionId = this.uuidv4()
  },
  computed: {
    count() {
      return this.selectedCount + 1
    }
  },
  methods: {
    gen() {
      this.loading = true
      this.headline = `Generujem ${this.count}x kupón`
      this.pairs = []

      this.$store.state.ky.post('mc', {
        json: {
          count: this.count,
          sessionId: this.sessionId,
        },
      })
          .json()
          .then(res => {
            this.pairs = res.pairs
            this.headline = "Klikni na tlačidlo nižšie pre generáciu 🍔"
          })
          .catch(e => {
            this.headline = `❌ ${e.message} ❌`
          }).finally(() => this.loading = false)
    },
    uuidv4() {
      return ([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
      )
    },
  },
}
</script>

<style scoped>

</style>